<template>
  <v-col
    cols="12"
    :md="6"
    class="align-center bottle-image"
  >
    <v-card
      class="align-content-center fill-height"
      :class="value.type"
    >
      <v-img
        class="align-end"
        height="400px"
        light
        contain
        :src="image"
        :class="value.type + ' ' + value.secondaryType"
      />

      <v-card-title
        class="text-center"
        :class="value.type + ' ' + value.titleColor"
        v-html=langValue.title
      />
      
      <v-card-subtitle
        class="pb-0"
        :class="value.type"
      >
        {{ value.alcohol }}
      </v-card-subtitle>

      <v-card-text
        class="text--primary"
        :class="value.type"
      >
        <div>{{ langValue.subtitle }}</div>
      </v-card-text>

      <v-divider
        class="mx-4"
        :class="value.type"
      />
      <v-card-actions class="card-actions-pricing">
        <v-btn
          v-for="(link, index) in value.links"
          :key="index"
          :class="value.type"
          color="orange"
          text
          @click="() => { registerLink(link) }"
        >
          <div v-html="link.btnText" />
        </v-btn>
        <v-spacer />
        <v-menu
          v-if="containsDropdown()"
          bottom
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
              class="orange--text"
              text
              v-on="on"
            >
              {{ langValue.moreBtn }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(link, i) in value.dropdownLinks"
              :key="i"
              @click="() => { registerLink(link) }"
            >
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
  export default {
    name: 'PricingCard',
    props: {
      value: {
        type: Object,
        default: () => ({}),
      },
    },
    data () {
      return {
        link: '',
        dropdown_font: [
          { text: 'Arial' },
          { text: 'Calibri' },
          { text: 'Courier' },
          { text: 'Verdana' },
        ],
      }
    },
    computed: {
      langValue () {
        return this.value[this.$store.state.lang]
      },
      image () {
        return require('@/assets/botellas/' + this.value.image)
      },
    },
    methods: {
      registerLink (link) {
        fbq('trackCustom', link.analyticsText, {
          content_name: link.analyticsText + ' Link',
          content_category: this.$store.state.lang,
          content_type: this.value.name,
        })
        this.$ua.trackEvent(link.analyticsText, 'Click', this.$store.state.lang + ' ' + this.value.name)
        this.goTo(link.url)
      },
      goTo (link) {
        window.open(link, '_blank')
      },
      containsDropdown () {
        if (this.value.dropdownLinks.length > 0) {
          return true
        }
        return false
      },
    },
  }
</script>

<style scoped>
  .bottle-image {
    padding-top: 10px;
  }
  .select-more-prices {
    padding-top: 10px;
    height:70px;
    color: orange;
    max-width: 50px;
  }
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: rgba(255, 152, 0, 0.87) !important;
  }
  .card-actions-pricing {
    overflow: hidden;
  }
  .coming-soon {
    background-color: #ffffff;
  }
  hr.coming-soon {
    visibility: hidden;
  }
  button.coming-soon {
    visibility: hidden;
    height: 0px;
  }
  .v-card__subtitle.coming-soon {
    /* visibility: hidden;
    height: 0px; */
    color: darkblue;
    display: block;
    padding-top: 15px;
    font-size: 18px;
    text-align: center;
  }
  .blueTitle {
    color: darkblue;
  }
  .v-card__text.coming-soon {
    visibility: hidden;
    height: 0px;
  }
  .v-card__title.coming-soon {
    color: orange;
    display: block;
    padding-top: 15px;
  }
  .v-image.coming-soon {
    height: 400px !important;
  }
  .v-image.blue-label {
    height: 394px !important;
  }
</style>
